/*-----------------------------------------------------------------------------------
    Template Name: Ayan Theme - Multipurpose Ecommerce HTML Template
    Template URI: http://html.ayantheme.com
    Author: Ayan CO
    Version: 1.0

    Note: This is Main js File.
-----------------------------------------------------------------------------------*/


  //Add animtion(animate.css) to element
  function addAnimate(el) {
    let timeout = 0;
    //data-delay is element ettribute to set delay time for start 
    if ($(el).attr("data-delay")) 
      timeout = $(el).attr("data-delay");

    setTimeout(() => {
      //data-animate is element ettribute to set type of animation
      $(el)
        .addClass($(el).attr("data-animate"))
        .addClass("animated")
        .removeClass("not-animated");

      if (
        $(el).attr("data-animate") &&
        $(el).attr("data-animate") == "text-animate"
      ) {
        let options = $(el).data("option");
        options = options.replace(/'/g, '"');
        $(el).textEffect(JSON.parse(options));
      }
    }, timeout);
  }

  //remove animtion from element
  function removeAnimate(el) {
    $(el)
      .addClass("not-animated")
      .removeClass("animated")
      .removeClass($(el).attr("data-animate"));
  }
  //smooth Scroll on click #Link anchor element
  function smoothScrollToTarget() {
    $("a.scroll-to-target").on("click", function (e) {
      var el = this;
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $($(el).attr("href")).offset().top - 150,
          },
          300
        );
      e.preventDefault();
    });
  }

  //execute headerSticky when the element have [header-sticky] attribute.
  function headerSticky() {
    const el = $("[header-sticky]");
    if (el.length > 0) {
      const headerScrollTop = el.offset().top;

      const defaults = {
        //specify type of headerSticky
        type: "showOnScroll",
        //specify minWidth of headerSticky
        minWidth: 991,
        //specify offset to show header
        offset: headerScrollTop,
      };

      let options = {};
      //data-option is element ettribute to set options of headerSticky
      if ($(el).data("option")) {
        options = JSON.parse($(el).data("option").replace(/'/g, '"'));
      }

      const settings = $.extend({}, defaults, options);
      if ($(window).width() > settings.minWidth) {
        let lastScrollTop = 0;
        $(window).on("scroll", function () {
          const st = $(this).scrollTop();

          if (st > settings.offset) {
            //showOnScrollUp type execute when scroll go to up
            if (settings.type.toLowerCase() == "showOnScrollUp".toLowerCase()) {
              if (st < lastScrollTop) {
                el.css("height", el.height());
                el.addClass("header-sticky");
              } else {
                el.css("height", "");
                el.removeClass("header-sticky");
              }
              lastScrollTop = st;
            }
            //showOnScrollDown type execute when scroll go to down
            else if (
              settings.type.toLowerCase() == "showOnScrollDown".toLowerCase()
            ) {
              if (st > lastScrollTop) {
                el.css("height", el.height());
                el.addClass("header-sticky");
              } else {
                el.css("height", "");
                el.removeClass("header-sticky");
              }
              lastScrollTop = st;
            } else {
              el.css("height", el.height());
              el.addClass("header-sticky");
            }
          } else {
            el.css("height", "");
            el.removeClass("header-sticky");
          }
        });
      }
    }
  }

  //set list items filter on Search page
  function filterSearch() {
    if ($(".filters .widget .widget__filter-search").length > 0) {
      $(".filters .widget .widget__filter-search").on("keyup", function () {
        const value = $(this).val().toLowerCase();

        $(this)
          .closest(".widget")
          .find(".widget__items ul li")
          .each(function () {
            const itemText = $(this).find(".widget__item-text").text();
            if (itemText && itemText.trim().toLowerCase().search(value) > -1) {
              $(this).removeClass("d-none");
            } else {
              $(this).addClass("d-none");
            }
          });
      });
    }
  }

  //hide & remove page loading on page load event
  function pageLoading() {
    const pageLoading = $(".page-loading");
    if (pageLoading.length>0) {
      pageLoading.fadeOut("1000");
      setTimeout(() => {
        pageLoading.remove();
      }, 1000);
    }
  }

  //execute the animation for all elements that have '.force-animated' css class on page load event
  function forceAnimate() {
    $(".force-animated").each(function () {
      $(this)
        .find(".not-animated")
        .each(function () {
          addAnimate(this);
        });
    });
    $(".force-animated.not-animated").each(function () {
          addAnimate(this);
    });
  }

  //opening & closing dropdown
  function toggelDropdownMenu() {
    if ($(".side-header").length<=0)
    {
      if ($(window).width() < 992) {
        //opening & closing dropdown menu on click in mobile and tablet size
        $(document)
          .off("click", ".primary-menu .dropdown > a")
          .on("click", ".primary-menu .dropdown > a", function () {
            $(this)
              .siblings(".dropdown-menu")
              .slideToggle()
              .parent()
              .toggleClass("open");
            return false;
          });
      } else {
        //opening & closing vertical category menu on click on menu title in desktop size
        $(document)
          .off("click", ".primary-menu .dropdown-category > a")
          .on("click", ".primary-menu .dropdown-category > a", function () {
            $(this).parent().toggleClass("show");
            return false;
          });
      }
    }
    else{
      //opening & closing dropdown menu on click (in side header)
      $(document).on(
        "click",
        ".side-header .primary-menu .dropdown > a",
        function () {
          $(this)
            .siblings(".dropdown-menu")
            .slideToggle()
            .parent()
            .toggleClass("open");
          return false;
        }
      );

      //opening & closing side header
      $(document).on("click", ".side-header #header-trigger", function () {
        $(this).closest("body").toggleClass("side-header-open");
        return false;
      });
    }
      

  }

  //execute countdown feature on all elements when the element have [data-countdown] attribute.
  function countdownAttr() {
    $("[data-countdown]").each(function () {
      $(this).countdown({ date: $(this).attr("data-countdown") });
    });
  }

  //execute swiper slider on all elements when the element have [data-swiper] attribute.
  function swiperAttr() {
    $("[data-swiper]").each(function (slider, index) {
      const el = this;

      const defaults = {
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          init: function (event) {
            //check if swiper slider is animation slider type
            if ($(el).hasClass("animate-slider")) {
              //execute animation on active index on init slider
              $(el)
                .find(".swiper-slide")
                .eq(event.activeIndex)
                .find(".not-animated")
                .each(function () {
                  addAnimate(this);
                });
            }
          },
        },
      };
      let options = {};
      //data-option is element ettribute to set options of swiper plugin
      if ($(el).data("option")) {
        options = JSON.parse($(el).data("option").replace(/'/g, '"'));
      }

      const settings = $.extend({}, defaults, options);

      if (settings.navigation && settings.navigation.nextEl)
        settings.navigation.nextEl = $(el).find(settings.navigation.nextEl)[0];
      if (settings.navigation && settings.navigation.prevEl)
        settings.navigation.prevEl = $(el).find(settings.navigation.prevEl)[0];

      if (settings.pagination && settings.pagination.el) {
        settings.pagination.el = $(el).find(settings.pagination.el)[0];
      }

      if ($(el).data("thumbs")) {
        const thumbsDefaults = {
          spaceBetween: 10,
          slidesPerView: 4,
          freeMode: true,
          watchSlidesProgress: true,
        };
        let thumbsOptions = {};
        if ($(el).data("thumbs")) {
          thumbsOptions = JSON.parse(
            $($(el).data("thumbs")).data("option").replace(/'/g, '"')
          );
        }

        const thumbsSettings = $.extend({}, thumbsDefaults, thumbsOptions);

        const thumbs = new Swiper($(el).data("thumbs"), thumbsSettings);

        settings.thumbs = {
          swiper: thumbs,
        };
      }

      const swiperEl = new Swiper(el, settings);

      swiperEl.on("slideChange", function () {
        if ($(el).hasClass("animate-slider")) {
          $(el)
            .find(".swiper-slide .animated")
            .each(function () {
              removeAnimate(this);
            });
          setTimeout(() => {
            $(el)
              .find(".swiper-slide")
              .eq(swiperEl.realIndex)
              .find(".not-animated")
              .each(function () {
                addAnimate(this);
              });
          }, 100);
        }
      });
    });
  }

  //execute owlCarousel slider on all elements when the element have [data-owlCarousel] attribute.
  function owlCarouselAttr() {
    $("[data-owlCarousel]").each(function (slider, index) {
      const el = this;
      const defaults = {
        loop: true,
        margin: 0,
        nav: true,
        autoplayHoverPause: true,
        autoHeight: true,
        dots: true,
        navText: [
          '<span class="material-symbols-outlined">chevron_left</span>',
          '<span class="material-symbols-outlined">chevron_right</span>',
        ],
        responsive: {
          0: {
            items: 1,
          },
        },
        onInitialized: function (event) {
          if ($(el).hasClass("animate-slider")) {
            //execute animation on active index on init slider
            $(el)
              .find(".owl-item")
              .eq(event.item.index)
              .find(".not-animated")
              .each(function () {
                addAnimate(this);
              });
          }
        },
      };

      let options = {};
      //data-option is element ettribute to set options of owlCarousel plugin
      if ($(el).data("option")) {
        options = JSON.parse($(el).data("option").replace(/'/g, '"'));
      }

      const settings = $.extend({}, defaults, options);
      const owl = $(el).owlCarousel(settings);

      owl.on("changed.owl.carousel", function (event) {
        if ($(el).hasClass("animate-slider")) {
          $(el)
            .find(".owl-item .animated")
            .each(function () {
              removeAnimate(this);
            });

          setTimeout(() => {
            $(el)
              .find(".owl-item")
              .eq(event.item.index)
              .find(".not-animated")
              .each(function () {
                addAnimate(this);
              });
          }, 100);
        }
      });
    });
  }

  //execute simplebar feature on all elements when the element have [data-simplebar] attribute.
  function simplebarAttr() {
    $("[data-simplebar]").each(function () {
      const el = this;

      const defaults = {
        autoHide: false,
      };
      let options = {};
      //data-option is element ettribute to set options of simplebar plugin
      if ($(el).data("option")) {
        options = JSON.parse($(el).data("option").replace(/'/g, '"'));
      }

      const settings = $.extend({}, defaults, options);

      const simpleBar = new SimpleBar(this, settings);
    });
  }

  //execute toc feature on all elements when the element have [data-toc] attribute.
  function tocAttr() {
    $("[data-toc]").each(function () {
      const el = this;

      const defaults = {
        sectionsSelector: "section",
        sectionsTitleSelector: "h2",
      };
      let options = {};
      //data-option is element ettribute to set options of toc function
      if ($(el).data("option")) {
        options = JSON.parse($(el).data("option").replace(/'/g, '"'));
      }

      const settings = $.extend({}, defaults, options);
      $(settings.sectionsSelector).makeToc({
        resultContainer: el,
        sectionsTitleSelector: settings.sectionsTitleSelector,
      });
    });
  }

  //execute imageZoom feature on all elements when the element have [data-imageZoom] attribute.
  function imageZoomAttr() {
    $("[data-imageZoom]").each(function () {
      const el = this;

      const defaults = {
        zoomSize: 4,
        disabledClass: "fullscreen",
        fullscreenContainer: "",
      };
      let options = {};
      //data-option is element ettribute to set options of imageZoom function
      if ($(el).data("option")) {
        options = JSON.parse($(el).data("option").replace(/'/g, '"'));
      }

      const settings = $.extend({}, defaults, options);
      $(el).imageZoom(settings);
    });
  }

  //execute card feature on all elements when the element have [data-card] attribute.
  function cardAttr() {
    $("[data-card]").each(function () {
      const el = this;

      const defaults = {
        form: ".credit-card-form",
        container: ".credit-card-wrapper",
      };
      let options = {};
      //data-option is element ettribute to set options of card plugin
      if ($(el).data("option")) {
        options = JSON.parse($(el).data("option").replace(/'/g, '"'));
      }

      const settings = $.extend({}, defaults, options);

      const card = new Card(settings);
    });
  }

  //execute chartist feature on all elements when the element have [data-chartist] attribute.
  function chartistAttr() {
    $("[data-chartist]").each(function () {
      const el = this;

      const defaults = {
        //  axisX: {
        //     labelInterpolationFnc: function(value) {
        //     return 'Calendar Week ' + value;
        //     }
        // }
      };
      let options = {};
      //data-option is element ettribute to set options of chartist plugin
      if ($(el).data("option")) {
        options = JSON.parse($(el).data("option").replace(/'/g, '"'));
      }

      let data = {};
      if ($(el).data("chart")) {
        data = JSON.parse($(el).data("chart").replace(/'/g, '"'));
      }

      // var data = {
      //     labels: ['1', '2', '3', '4', '5', '6'],
      //     series: [
      //         {
      //         data: [1, 2, 3, 5, 8, 13]
      //         }
      //     ]
      //     };

      const responsiveOptions = [
        [
          "screen and (min-width: 641px) and (max-width: 1024px)",
          {
            showPoint: false,
            axisX: {
              labelInterpolationFnc: function (value) {
                return "Week " + value;
              },
            },
          },
        ],
        [
          "screen and (max-width: 640px)",
          {
            showLine: false,
            axisX: {
              labelInterpolationFnc: function (value) {
                return "W" + value;
              },
            },
          },
        ],
      ];

      const settings = $.extend({}, defaults, options);

      new Chartist.Line(el, data, settings, responsiveOptions);
    });
  }

  //execute nouislider feature on all elements when the element have [data-nouislider] attribute.
  function nouisliderAttr() {
    $("[data-nouislider]").each(function () {
      const el = this;

      const defaults = {
        format: wNumb({
          decimals: 0,
        }),
        connect: true,
        pips: { mode: "count", values: 5 },
      };
      //data-option is element ettribute to set options of nouislider plugin
      const options = JSON.parse($(el).data("option").replace(/'/g, '"'));

      const settings = $.extend({}, defaults, options);

      noUiSlider.create(el, settings);

      const minInput = $(el)
        .closest(".range-slider")
        .find(".range-slider-value-min")[0];
      const maxInput = $(el)
        .closest(".range-slider")
        .find(".range-slider-value-max")[0];
      const sliderValues = [minInput, maxInput];

      el.noUiSlider.on("update", function (values, handle) {
        sliderValues[handle].value = values[handle];
      });

      minInput.addEventListener("change", function () {
        el.noUiSlider.set([this.value, null]);
      });

      maxInput.addEventListener("change", function () {
        el.noUiSlider.set([null, this.value]);
      });
    });
  }

  //show & hide password when click on password toggle.
  function passwordToggle() {
    $('input[type="checkbox"].password-toggle-check').on("click", function () {
      const $pwd = $(this).closest(".password-toggle").children("input");
      if ($pwd.attr("type") === "password") {
        $pwd.attr("type", "text");
      } else {
        $pwd.attr("type", "password");
      }
    });
  }

  //execute bootstrp toggle feature on all elements when the element have '.toggle' css class.
  function bootstrpToggle() {
    $(".toggle").each(function () {
      const el = this;
      $(el).children(".toggle-body").slideUp(1);
      $(el)
        .children(".toggle-header")
        .off("click")
        .on("click", function (e) {
          e.preventDefault();
          if ($(el).hasClass("toggle-active")) {
            $(el).children(".toggle-body").slideUp(300);
            $(el).removeClass("toggle-active");
          } else {
            $(el).children(".toggle-body").slideDown(300);
            $(el).addClass("toggle-active");
          }
        });
    });
  }

  function fileDropMessage() {
    //show message when file input seted a file.
    $(".file-drop-input").on("change", function (ev) {
      const el = this;
      $(el)
        .siblings(".file-drop-message")
        .text(
          el.files
            ? el.files.length > 1
              ? el.files.length + " files selected"
              : el.files[0].name
            : ""
        );
    });
  }

  //execute filter data list feature on all elements when the element have [data-filter] attribute.
  //[data-filter] attribute value is target selector and only show elements that have css class with value [data-filter] in selected bootstrap tab

  function selectedBootstrapTabContentFilter() {
    $("[data-filter]").each(function (index, el) {
      $(el).on("show.bs.tab", () => {
        const targetClass = $(el).attr("data-filter");
        const parent = $(el).attr("data-parent");
        const pane = $(parent + " " + targetClass);
        const sibling = $(parent + " .tab-pane.active");

        if (sibling) {
          sibling.each((i, element) => {
            $(element).removeClass("show").removeClass("active");
          });
        }

        if (pane) {
          pane.each((i, element) => {
            $(element).addClass("active").addClass("show");
          });
        }
      });
    });
    
  }

  //open dropdown menu with current url value
  function autoOpenMenuWithCurrentUrl() {
    const url = window.location.pathname;
    // alert(url);
    // if (url && url.startsWith('/'))
    //     url = url.substring(1);
    $(".sidebar-menu li").each(function () {
      if ($("a", this).attr("href")) {
        if (
          url.toLowerCase().endsWith($("a", this).attr("href").toLowerCase())
        ) {
          openMenu(this);

          $(this)
            .parents("li")
            .each(function () {
              openMenu(this);
            });
        }
      }
    });
  }

  //open passed menu
  function openMenu(el) {
    if(!el)
      return;
    $(el).addClass("active");
    const btn = $(el).children("a.collapsed").eq(0);
    if (
      btn &&
      $(el).children("ul").length > 0 &&
      bootstrap &&
      bootstrap.Collapse &&
      btn.attr("data-bs-target")
    ) {
      new bootstrap.Collapse($(btn.attr("data-bs-target"))[0], {
        //toggle: false
      });
    }
  }

  //check bootstrap form validation when the element have '.needs-validation' css class.
  function checkBootstrpFormValidation() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    // Loop over them and prevent submission
    $(".needs-validation").each((index, form) => {
      $(form).on("submit", (event) => {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }

        form.addClass("was-validated");
      });
    });

  }

  //execute change Label feature on all elements when the element have [data-bs-label] attribute.
  //change target element text when clicked on element

  function changeLabelAttr() {
    $("[data-bs-label]").each((index, el) => {
      $(el).on("change", () => {
        var id = $(el).attr('data-bs-label');
        $('#'+id).text($(el).val());
      });
    });

   
  }

  //trigger accordion button when clicked on radio button.
  function triggerAccordionButton() {
    $(".accordion .accordion-header .accordion-button").on(
      "click",
      function () {
        const radioInput = $(this).siblings('input[type="radio"]');
        if (radioInput) {
          radioInput.trigger("click");
        }
      }
    );

    $('.accordion .accordion-header input[type="radio"]').on(
      "click",
      function () {
        const accordionButton = $(this).siblings(".accordion-button");
        if (accordionButton && accordionButton.attr("href")) {
          //accordionButton.trigger('click');
          new bootstrap.Collapse($(accordionButton.attr("href"))[0], {
            //toggle: false
          });
        }
      }
    );
  }

  //execute bootstrap tooltip feature on all elements when the element have [data-bs-toggle="tooltip"] attribute.
  function tooltipAttr() {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    if (
      tooltipTriggerList &&
      tooltipTriggerList.length > 0 &&
      bootstrap &&
      bootstrap.Tooltip
    ) {
      const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      });
    }
  }

  //execute bootstrap popover feature on all elements when the element have [data-bs-toggle="popover"] attribute.
  function popoverAttr() {
    //set bootstrap Popover
    const popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    if (
      popoverTriggerList &&
      popoverTriggerList.length>0 &&
      bootstrap &&
      bootstrap.Popover
    ) {
      const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl);
      });
    }
  }

  function triggerFileInput(){
     //trigger file input
    $(".file-drop-area .file-drop-btn").on("click", function () {
      $(this).siblings(".file-drop-input").trigger("click");
    });

    //trigger file input
    $(".input-file-trigger-btn").on("click", function () {
      $(this).siblings('input[type="file"]').trigger("click");
    });
  }

  

 /* ==========================================================================
   When document is ready, do
   ========================================================================== */
   
jQuery(function () {
   tooltipAttr();
   popoverAttr();
   
  filterSearch();
  toggelDropdownMenu();
  countdownAttr();
  swiperAttr();
  owlCarouselAttr();
  simplebarAttr();
  tocAttr();
  imageZoomAttr();
  cardAttr();
  chartistAttr();
  nouisliderAttr();
  passwordToggle();
  bootstrpToggle();
  fileDropMessage();
  selectedBootstrapTabContentFilter();
  autoOpenMenuWithCurrentUrl();
  checkBootstrpFormValidation();
  changeLabelAttr();
  triggerAccordionButton();
  triggerFileInput();
  headerSticky();
  smoothScrollToTarget();
});




  /* ==========================================================================
   When document is completed load, do
   ========================================================================== */
  $(window).on("load", function () {
    pageLoading();    
    forceAnimate();
  });

  /* ==========================================================================
   When document is scroll, do
   ========================================================================== */
  const scrollTop = 300;

  $(window).on("scroll", function () {
    //execute the animation on elements that have '.not-animated' css class when scrolling on the element.
    $(".not-animated").each(function () {
      const $this = this;
      if (
        $(window).scrollTop() + $(window).height() - 100 >=
        $($this).offset().top
      ) {
        {
          addAnimate($this);
        }
      }
    });

    //show scroll to top button on scroll to down.
    if ($(window).scrollTop() > scrollTop) {
      $(".btn-scroll-top").addClass("show");
    } else {
      $(".btn-scroll-top").removeClass("show");
    }
  });
